* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

::selection {
  color: #ffff;
  background-color: #46a836;
}

@font-face {
  font-family: "gotham-black";
  src: local("gotham-black"),
    url(./Fonts/gotham/Gotham-Black.otf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "gotham-bold";
  src: local("gotham-bold"), url(./Fonts/gotham/Gotham-Bold.otf) format("woff");
}
